import { decryptImage, rc4Decrypt } from '@/utils/crypto'

export function arraybufferToBase64(t) {
  return new Promise(function(e) {
    const n = new Blob([t])
    const r = new FileReader()
    r.onload = function(t) {
      const n = t.target.result
      const r = n.substring(n.indexOf(',') + 1)
      e(r)
    }
    r.readAsDataURL(n)
  })
}

export function getExtension(src) {
  const ext = src.substring(src.lastIndexOf('.') + 1)
  return ext === 'jpg' ? 'jpeg' : ext
}

export async function getDecryptedURL(src, $axios, key, iv) {
  try {
    let SrcDomain = src

    if (src.lastIndexOf('?') + 1 === src.length) {
      SrcDomain = SrcDomain.substring(0, src.length - 1)
    }

    const ext = getExtension(SrcDomain)

    if (!['jpg', 'jpeg', 'png', 'ico', 'gif'].includes(ext)) {
      return Promise.reject(new Error('Failed!'))
    }

    const res = await $axios.get(src, { responseType: 'arraybuffer' })
    const base64 = await arraybufferToBase64(res.data)
    const srcstring = await decryptImage(base64, key, iv)
    return `data:image/${ext};base64,${srcstring}`
  } catch (error) {
    return Promise.reject(new Error('Failed!'))
  }
}

/**
 * Decrypt image URL to Base64
 *
 * @param {string}  url 
 * @param {axios}   $axios 
 * @param {string}  key
 *
 * @returns {string}
 */
export async function getDecryptedUrlRc4(url, $axios, key) {
  try {
    let urlDomain = url

    if (url.lastIndexOf('?') + 1 === url.length) {
      urlDomain = urlDomain.substring(0, url.length - 1)
    }

    const ext = getExtension(urlDomain)

    if (!['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(ext)) {
      return Promise.reject(new Error('Failed!'))
    }

    // request
    const response = await $axios.get(url, { responseType: 'arraybuffer' })
    // get array buffer
    const arrayBuffer = response.data
    // byte array
    const byteArray = new Uint8Array(arrayBuffer)
    // decode with ARC4
    const decryptedBytes = rc4Decrypt(byteArray, key)

    // convert decrypted bytes to Base64 string
    let binary = []
    for (let i = 0; i < decryptedBytes.length; i++) {
      binary.push(String.fromCharCode(decryptedBytes[i]))
    }
    const base64String = btoa(binary.join(''))

    return `data:image/${ext};base64,${base64String}`
  } catch (error) {
    return Promise.reject(new Error('Failed!'))
  }
}
