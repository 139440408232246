import CryptoJS from 'crypto-js'

function decryptImage(encrypted, key, iv) {
  const mediaKey = CryptoJS.enc.Utf8.parse(key)
  const mediaIv = CryptoJS.enc.Utf8.parse(iv)

  const decrypt = CryptoJS.AES.decrypt(encrypted, mediaKey, {
    iv: mediaIv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.NoPadding
  })

  return decrypt.toString(CryptoJS.enc.Base64)
}

/**
 * ARC4 decryption
 *
 * @param {Uint8Array}  encryptedByteArray 
 * @param {string}      key
 *
 * @returns {Uint8Array}
 */
function rc4Decrypt(encryptedByteArray, key) {
  // create RC4 encryption instance
  const rc4 = CryptoJS.algo.RC4.createEncryptor(CryptoJS.enc.Utf8.parse(key))

  // encrypt the data
  const encryptedData = rc4.process(CryptoJS.lib.WordArray.create(encryptedByteArray))
  const encryptedBytes = new Uint8Array(encryptedData.words.length * 4)
  for (let i = 0; i < encryptedData.words.length; i++) {
    encryptedBytes[i * 4] = (encryptedData.words[i] >> 24) & 0xff
    encryptedBytes[i * 4 + 1] = (encryptedData.words[i] >> 16) & 0xff
    encryptedBytes[i * 4 + 2] = (encryptedData.words[i] >> 8) & 0xff
    encryptedBytes[i * 4 + 3] = encryptedData.words[i] & 0xff
  }

  return encryptedBytes
}

export {
  decryptImage,
  rc4Decrypt,
}
