<template>
  <img v-if="decryptedImage" :src="decryptedImage" :alt="alt" :title="alt" :class="className" />
  <img
    v-else-if="isPlaceholder"
    :src="require('@/assets/images/placeholder.png')"
    :alt="alt"
    :title="alt"
    :class="className" />
</template>

<script>
  import { getDecryptedURL } from '@/utils/image-decrypt';

  export default {
    props: {
      encryptedImageUrl: {
        type: String,
        required: true,
      },
      alt: {
        type: String,
      },
      className: {
        type: Object,
      },
      isPlaceholder: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        decryptedImage: null,
      };
    },
    mounted() {
      this.decryptImage();
    },
    methods: {
      decryptImage() {
        try {
          getDecryptedURL(
            this.encryptedImageUrl,
            this.$axios,
            process.env.VUE_APP_ENCRYPTION_IMAGE_KEY,
            process.env.VUE_APP_ENCRYPTION_IMAGE_IV,
          ).then(response => {
            this.decryptedImage = response;
          });
        } catch (error) {
          return Promise.reject(new Error('Failed!'));
        }
      },
    },
  };
</script>
