var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"min-h-[100dvh] flex flex-col relative z-30 fade-up"},[_c('div',{staticClass:"fade-none w-full h-[50px] md:h-[75px] px-3 md:px-4 xl:px-0 fixed z-20 bg-gradient-to-b from-[#3B0382] to-[#8C4697] border-b-2 border-[#8D34FF]"},[_c('div',{staticClass:"w-full h-full max-w-[848px] mx-auto flex justify-between items-center gap-4 md:gap-7"},[_vm._m(0),_c('marquee',{class:[
          'bg-[#9543BC] py-1 rounded-full relative overflow-hidden',
          'before:w-10 before:absolute before:z-10 before:top-0 before:bottom-0 before:left-0 before:bg-gradient-to-r before:from-[#9543BC] before:from-15%',
          'after:w-10 after:absolute after:z-10 after:top-0 after:bottom-0 after:right-0 after:bg-gradient-to-l after:from-[#9543BC] after:from-15%',
        ]},_vm._l((_vm.notices),function({ content },idx){return _c('p',{key:idx,staticClass:"inline-block ml-14 first-of-type:ml-0 text-sm text-white"},[_vm._v(" "+_vm._s(content)+" ")])}),0)],1)]),_vm._m(1),_c('div',{staticClass:"w-full max-w-[1200px] mx-auto px-3 md:px-4 xl:px-0 pt-[50px] md:pt-[75px] flex-auto relative z-10"},[_c('div',{staticClass:"relative z-10 max-w-[848px] mx-auto pt-4 xl:pt-10 pb-4 xl:pb-20 flex flex-col gap-6 xl:gap-10"},[(_vm.topBanners?.advList?.length || true)?_c('div',{staticClass:"fade-none"},[_c('swiper',{staticClass:"rounded-xl",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.topBanners.advList),function(banner){return _c('swiper-slide',{key:`banner-${banner.id}`},[_c('a',{attrs:{"href":banner?.link,"target":"_blank"},on:{"click":function($event){return _vm.handleCountClick({ advId: banner.id })}}},[_c('encrypted-image-rc4',{key:banner.id,attrs:{"encrypted-image-url":_vm.getImgURL(banner?.picUrl),"alt":banner.name,"class-name":{ 'w-full aspect-[848/206] object-cover rounded-xl': true }}})],1)])}),_c('div',{staticClass:"swiper-pagination !bottom-1 [&>span]:w-8 [&>span]:h-1 [&>span]:rounded-xl",attrs:{"slot":"pagination"},slot:"pagination"})],2)],1):_vm._e(),_c('div',{staticClass:"grid grid-flow-col"},_vm._l((_vm.categories),function({ id, name }){return _c('button',{key:id,class:[
            'pb-2 text-sm xl:text-base text-[#FFCE4F] [text-shadow:_0px_0px_4px_rgba(168,224,255,0.50)] font-medium border-b-2',
            'transition hover:border-[#ffce4f]',
            { 'border-[#ffce4f]': _vm.selectedCategory?.id === id },
          ],on:{"click":function($event){return _vm.handleMenuClick(id)}}},[_vm._v(" "+_vm._s(name)+" ")])}),0),_c('div',{staticClass:"fade-none grid grid-cols-3 md:grid-cols-5 gap-y-6 xl:gap-y-8 gap-x-4 xl:gap-x-12"},_vm._l((_vm.selectedDataList),function(ad,idx){return _c('a',{key:idx,staticClass:"max-w-[120px] space-y-2 border-t-2 border-white rounded-xl md:rounded-2xl relative cursor-pointer transition hover:scale-105",attrs:{"href":ad?.link,"target":"_blank"},on:{"click":function($event){return _vm.handleCountClick({ advId: ad.id })}}},[_c('encrypted-image-rc4',{key:ad.id,attrs:{"encrypted-image-url":_vm.getImgURL(ad?.picUrl),"alt":ad.name,"is-placeholder":true,"class-name":{ 'w-full aspect-[1/1] object-cover rounded-xl md:rounded-2xl': true }}}),_c('p',{staticClass:"w-full px-2 py-1.5 bg-gradient-to-b from-[#A0E8FF] to-[#7C3ECD] border-b-2 border-white rounded-xl truncate text-sm text-center text-white"},[_vm._v(" "+_vm._s(ad.name)+" ")])],1)}),0),(_vm.centerBanners?.advList?.length || true)?_c('div',{staticClass:"fade-none"},[_c('swiper',{staticClass:"rounded-xl",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.centerBanners.advList),function(banner){return _c('swiper-slide',{key:`banner-${banner.id}`},[_c('a',{attrs:{"href":banner?.link,"target":"_blank"},on:{"click":function($event){return _vm.handleCountClick({ advId: banner.id })}}},[_c('encrypted-image-rc4',{key:banner.id,attrs:{"encrypted-image-url":_vm.getImgURL(banner?.picUrl),"alt":banner.name,"class-name":{ 'w-full aspect-[21/5] object-cover rounded-xl': true }}})],1)])}),_c('div',{staticClass:"swiper-pagination !bottom-1 [&>span]:w-8 [&>span]:h-1 [&>span]:rounded-xl",attrs:{"slot":"pagination"},slot:"pagination"})],2)],1):_vm._e(),_c('div',{staticClass:"flex flex-col gap-4"},[_vm._m(2),_c('div',{staticClass:"fade-none grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-4 xl:gap-x-8"},_vm._l((_vm.playings.advList),function(item,idx){return _c('a',{key:idx,class:[
              'flex items-center gap-3 p-2.5 md:p-4 rounded-xl border-t border-[rgba(186,255,247,0.40)] cursor-pointer transition hover:-translate-y-1 hover:shadow-lg',
              'bg-gradient-to-b from-[rgba(196,168,255,0)] vai-[rgba(155,153,255,0.30)] to-[rgba(155,153,255,0.30)] shadow-[0px_2px_4px_0px_rgba(0,0,0,0.25)]',
            ],attrs:{"href":item?.link,"target":"_blank"},on:{"click":function($event){return _vm.handleCountClick({ advId: item.id })}}},[_c('encrypted-image-rc4',{key:item.id,attrs:{"encrypted-image-url":_vm.getImgURL(item?.picUrl),"alt":item.name,"is-placeholder":true,"class-name":{
                'w-[100px] md:w-28 aspect-square object-cover border-t border-b border-solid border-white rounded-xl [box-shadow:_0px_0px_10px_0px_rgba(0,_0,_0,_0.60)]': true,
              }}}),_c('div',{staticClass:"flex flex-col gap-1 text-sm text-white"},[_c('p',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"flex items-center gap-1"},[_vm._l((5),function(i){return _c('span',{key:i,class:[
                    'icon-star [&:before]:text-gray-900',
                    { '[&:before]:!text-[#82FFF7]': (item.rating || 0) >= i },
                  ]})}),_c('p',{staticClass:"ml-2"},[_vm._v("下载量：")]),_c('div',[_c('span',{staticClass:"text-[#82FFF7]"},[_vm._v(_vm._s(item?.downloadCount || 0))]),_c('span',[_vm._v("万")])])],2),_c('p',{staticClass:"text-[#82FFF7]"},[_vm._v(_vm._s(item?.description))])])],1)}),0)]),_c('div',{staticClass:"flex flex-col gap-4"},[_vm._m(3),_c('div',{staticClass:"fade-none grid grid-cols-2 md:grid-cols-4 gap-y-4 xl:gap-y-6 gap-x-4 xl:gap-x-8"},_vm._l((_vm.contacts),function({ id, name, link, picUrl },idx){return _c('a',{key:idx,class:[
              'flex flex-col items-center gap-4 py-5 px-3 rounded-xl border-t border-[rgba(186,255,247,0.40)] cursor-pointer transition hover:-translate-y-1 hover:shadow-lg',
              'bg-gradient-to-b from-[rgba(196,168,255,0)] vai-[rgba(155,153,255,0.30)] to-[rgba(155,153,255,0.30)] shadow-[0px_2px_4px_0px_rgba(0,0,0,0.25)]',
            ],attrs:{"href":link,"target":"_blank"}},[_c('encrypted-image-rc4',{key:id,attrs:{"encrypted-image-url":_vm.getImgURL(picUrl),"alt":name,"is-placeholder":true,"class-name":{ 'w-20 aspect-square object-contain': true }}}),_c('p',{staticClass:"px-2 py-1 text-xs bg-gradient-to-b from-[#A0E8FF] to-transparent rounded-full border-b border-white text-white truncate"},[_vm._v(" "+_vm._s(name)+" ")])],1)}),0)])])]),_c('Modal',{class:[{ '!hidden': _vm.isModalVisible }],model:{value:(_vm.isModalShow),callback:function ($$v) {_vm.isModalShow=$$v},expression:"isModalShow"}},[_c('template',{slot:"content"},_vm._l((_vm.announcements?.advList || []),function({ id, name, link, picUrl },idx){return _c('a',{key:id,staticClass:"animate-none w-full hidden absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 first:block",attrs:{"href":link,"target":"_blank","rel":"noopener noreferrer"},on:{"click":function($event){return _vm.handleCountClick({ advId: id })}}},[_c('encrypted-image-rc4',{key:id,attrs:{"encrypted-image-url":_vm.getImgURL(picUrl),"alt":name,"onImageLoaded":_vm.handleImageLoaded,"class-name":{ 'w-full': true }}}),_c('span',{class:[
            'w-6 h-6 md:w-10 md:h-10 flex justify-center items-center text-lg cursor-pointer rounded',
            'absolute top-0 right-0  bg-gray-900/50',
            'transition hover:bg-neutral-900/75',
          ],on:{"click":function($event){$event.preventDefault();return _vm.handleCloseModalAd($event, idx)}}},[_c('span',{staticClass:"icon-close pointer-events-none"})])],1)}),0)],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"min-w-[100px] h-8 md:h-10 aspect-[31/8] object-contain",attrs:{"src":require("@/assets/images/logo.webp"),"alt":"branding","loading":"lazy"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fade-none w-full max-w-[1200px] h-[100dvh] mx-auto fixed z-0 top-0 left-1/2 -translate-x-1/2 hidden xl:block"},[_c('div',{staticClass:"absolute z-0 top-0 bottom-0 left-0 flex items-end"},[_c('img',{staticClass:"h-[calc(100dvh/2.25)] 2xl:h-[calc(100dvh/1.5)] max-h-[1700px] -translate-x-1/4 2xl:-translate-x-3/4",attrs:{"src":require("@/assets/images/floating-left.webp"),"loading":"lazy","alt":"img-floating-left"}})]),_c('div',{staticClass:"absolute z-0 top-0 bottom-0 right-0 flex items-end"},[_c('img',{staticClass:"h-[calc(100dvh/2.25)] 2xl:h-[calc(100dvh/1.5)] max-h-[1700px] translate-x-1/4 2xl:translate-x-3/4",attrs:{"src":require("@/assets/images/floating-right.webp"),"loading":"lazy","alt":"img-floating-left"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center gap-1 md:gap-2"},[_c('img',{staticClass:"h-5 md:h-6",attrs:{"src":require("@/assets/images/svg/icon.svg"),"alt":"tag-icon"}}),_c('h6',{staticClass:"text-[#FFCE4F] text-base md:text-xl font-medium"},[_vm._v("大家都在玩")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center gap-1 md:gap-2"},[_c('img',{staticClass:"h-5 md:h-6",attrs:{"src":require("@/assets/images/svg/icon.svg"),"alt":"tag-icon"}}),_c('h6',{staticClass:"text-[#FFCE4F] text-xl font-medium"},[_vm._v("联系我们")])])
}]

export { render, staticRenderFns }