<template>
  <div class="modal" v-if="isModalShow" @click="toggleModalStatus">
    <div class="modal-cover" v-if="showCover" @click="toggleModalStatus"></div>
    <div class="animate-none modal-box" :class="boxPosition" @click.stop>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    destroyed() {
      this.lockScroll && this.setScreenScrolling('auto');
    },
    data() {
      return {};
    },
    model: {
      prop: 'isModalShow',
      event: 'toggleModalStatus',
    },
    props: {
      title: String,
      boxPosition: {
        //  full | center | customize
        type: String,
        default: 'center',
      },
      isModalShow: {
        type: Boolean,
        default: false,
      },
      showCover: {
        type: Boolean,
        default: true,
      },
      iconClass: {
        type: String,
        default: 'close-line',
      },
      lockScroll: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      toggleModalStatus() {
        this.$emit('toggleModalStatus', !this.isModalShow);
      },
      setScreenScrolling(attr) {
        document.body.style.setProperty('overflow', attr);
      },
    },
    watch: {
      isModalShow(newVal) {
        this.lockScroll && this.setScreenScrolling(newVal ? 'hidden' : 'auto');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;

    .modal-cover {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba($color: #000, $alpha: 0.6);
    }

    .modal-box {
      width: 65vw;
      max-width: 560px;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin: auto;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 99;
      min-height: 44px;

      @media screen and (max-width: 639.98px) {
        width: 75vw;
      }
    }
  }
</style>
