<template>
  <img
    v-if="decryptedImage"
    :src="decryptedImage"
    :alt="alt"
    :title="alt"
    :class="className"
    @error="ev => (ev.target.src = require('@/assets/images/placeholder.png'))" />
  <img
    v-else-if="isPlaceholder"
    :src="require('@/assets/images/placeholder.png')"
    :alt="alt"
    :title="alt"
    :class="className" />
</template>

<script>
  import { getDecryptedUrlRc4 } from '@/utils/image-decrypt';

  export default {
    props: {
      encryptedImageUrl: {
        type: String,
        required: true,
      },
      alt: {
        type: String,
      },
      className: {
        type: Object,
      },
      isPlaceholder: {
        type: Boolean,
        default: false,
      },
      onImageLoaded: {
        type: Function,
      },
    },
    data() {
      return {
        decryptedImage: null,
      };
    },
    mounted() {
      this.decryptImage();
    },
    methods: {
      decryptImage() {
        try {
          getDecryptedUrlRc4(this.encryptedImageUrl, this.$axios, process.env.VUE_APP_ENCRYPTION_IMAGE_KEY).then(
            response => {
              this.decryptedImage = response;
              if (this.onImageLoaded) {
                if (this.decryptedImage) {
                  this.onImageLoaded({ success: true, data: this.decryptedImage });
                } else {
                  this.onImageLoaded({ success: false });
                }
              }
            },
          );
        } catch (error) {
          if (this.onImageLoaded) {
            this.onImageLoaded({ success: false });
          }

          return Promise.reject(new Error('Failed!'));
        }
      },
    },
  };
</script>
