import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import HomeModule from '@/store/modules/home-module'

export default new Vuex.Store({
  state: {

  },
  mutations: {
    
  },
  actions: {
    
  },
  modules: {
    HomeModule,
  },
})
