<template>
  <div class="min-h-[100dvh] flex flex-col relative z-30 fade-up">
    <div
      class="fade-none w-full h-[50px] md:h-[75px] px-3 md:px-4 xl:px-0 fixed z-20 bg-gradient-to-b from-[#3B0382] to-[#8C4697] border-b-2 border-[#8D34FF]">
      <div class="w-full h-full max-w-[848px] mx-auto flex justify-between items-center gap-4 md:gap-7">
        <a href="/">
          <img
            src="@/assets/images/logo.webp"
            alt="branding"
            loading="lazy"
            class="min-w-[100px] h-8 md:h-10 aspect-[31/8] object-contain" />
        </a>
        <marquee
          :class="[
            'bg-[#9543BC] py-1 rounded-full relative overflow-hidden',
            'before:w-10 before:absolute before:z-10 before:top-0 before:bottom-0 before:left-0 before:bg-gradient-to-r before:from-[#9543BC] before:from-15%',
            'after:w-10 after:absolute after:z-10 after:top-0 after:bottom-0 after:right-0 after:bg-gradient-to-l after:from-[#9543BC] after:from-15%',
          ]">
          <!-- prettier-ignore -->
          <p v-for="({ content }, idx) in notices" :key="idx" class="inline-block ml-14 first-of-type:ml-0 text-sm text-white">
            {{ content }}
          </p>
        </marquee>
      </div>
    </div>

    <div
      class="fade-none w-full max-w-[1200px] h-[100dvh] mx-auto fixed z-0 top-0 left-1/2 -translate-x-1/2 hidden xl:block">
      <div class="absolute z-0 top-0 bottom-0 left-0 flex items-end">
        <img
          src="@/assets/images/floating-left.webp"
          loading="lazy"
          alt="img-floating-left"
          class="h-[calc(100dvh/2.25)] 2xl:h-[calc(100dvh/1.5)] max-h-[1700px] -translate-x-1/4 2xl:-translate-x-3/4" />
      </div>
      <div class="absolute z-0 top-0 bottom-0 right-0 flex items-end">
        <img
          src="@/assets/images/floating-right.webp"
          loading="lazy"
          alt="img-floating-left"
          class="h-[calc(100dvh/2.25)] 2xl:h-[calc(100dvh/1.5)] max-h-[1700px] translate-x-1/4 2xl:translate-x-3/4" />
      </div>
    </div>

    <div class="w-full max-w-[1200px] mx-auto px-3 md:px-4 xl:px-0 pt-[50px] md:pt-[75px] flex-auto relative z-10">
      <div class="relative z-10 max-w-[848px] mx-auto pt-4 xl:pt-10 pb-4 xl:pb-20 flex flex-col gap-6 xl:gap-10">
        <div v-if="topBanners?.advList?.length || true" class="fade-none">
          <swiper class="rounded-xl" :options="swiperOption">
            <swiper-slide v-for="banner in topBanners.advList" :key="`banner-${banner.id}`">
              <a :href="banner?.link" @click="handleCountClick({ advId: banner.id })" target="_blank">
                <encrypted-image-rc4
                  :encrypted-image-url="getImgURL(banner?.picUrl)"
                  :alt="banner.name"
                  :class-name="{ 'w-full aspect-[848/206] object-cover rounded-xl': true }"
                  :key="banner.id" />
              </a>
            </swiper-slide>
            <!-- prettier-ignore -->
            <div class="swiper-pagination !bottom-1 [&>span]:w-8 [&>span]:h-1 [&>span]:rounded-xl" slot="pagination"></div>
          </swiper>
        </div>

        <div class="grid grid-flow-col">
          <button
            v-for="{ id, name } in categories"
            :key="id"
            :class="[
              'pb-2 text-sm xl:text-base text-[#FFCE4F] [text-shadow:_0px_0px_4px_rgba(168,224,255,0.50)] font-medium border-b-2',
              'transition hover:border-[#ffce4f]',
              { 'border-[#ffce4f]': selectedCategory?.id === id },
            ]"
            @click="handleMenuClick(id)">
            {{ name }}
          </button>
        </div>
        <div class="fade-none grid grid-cols-3 md:grid-cols-5 gap-y-6 xl:gap-y-8 gap-x-4 xl:gap-x-12">
          <a
            v-for="(ad, idx) in selectedDataList"
            :key="idx"
            :href="ad?.link"
            target="_blank"
            class="max-w-[120px] space-y-2 border-t-2 border-white rounded-xl md:rounded-2xl relative cursor-pointer transition hover:scale-105"
            @click="handleCountClick({ advId: ad.id })">
            <encrypted-image-rc4
              :encrypted-image-url="getImgURL(ad?.picUrl)"
              :alt="ad.name"
              :is-placeholder="true"
              :class-name="{ 'w-full aspect-[1/1] object-cover rounded-xl md:rounded-2xl': true }"
              :key="ad.id" />
            <p
              class="w-full px-2 py-1.5 bg-gradient-to-b from-[#A0E8FF] to-[#7C3ECD] border-b-2 border-white rounded-xl truncate text-sm text-center text-white">
              {{ ad.name }}
            </p>
          </a>
        </div>

        <div v-if="centerBanners?.advList?.length || true" class="fade-none">
          <swiper class="rounded-xl" :options="swiperOption">
            <swiper-slide v-for="banner in centerBanners.advList" :key="`banner-${banner.id}`">
              <a :href="banner?.link" @click="handleCountClick({ advId: banner.id })" target="_blank">
                <encrypted-image-rc4
                  :encrypted-image-url="getImgURL(banner?.picUrl)"
                  :alt="banner.name"
                  :class-name="{ 'w-full aspect-[21/5] object-cover rounded-xl': true }"
                  :key="banner.id" />
              </a>
            </swiper-slide>
            <!-- prettier-ignore -->
            <div class="swiper-pagination !bottom-1 [&>span]:w-8 [&>span]:h-1 [&>span]:rounded-xl" slot="pagination"></div>
          </swiper>
        </div>

        <div class="flex flex-col gap-4">
          <div class="flex items-center gap-1 md:gap-2">
            <img src="@/assets/images/svg/icon.svg" alt="tag-icon" class="h-5 md:h-6" />
            <h6 class="text-[#FFCE4F] text-base md:text-xl font-medium">大家都在玩</h6>
          </div>
          <div class="fade-none grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-4 xl:gap-x-8">
            <a
              v-for="(item, idx) in playings.advList"
              :key="idx"
              :href="item?.link"
              target="_blank"
              :class="[
                'flex items-center gap-3 p-2.5 md:p-4 rounded-xl border-t border-[rgba(186,255,247,0.40)] cursor-pointer transition hover:-translate-y-1 hover:shadow-lg',
                'bg-gradient-to-b from-[rgba(196,168,255,0)] vai-[rgba(155,153,255,0.30)] to-[rgba(155,153,255,0.30)] shadow-[0px_2px_4px_0px_rgba(0,0,0,0.25)]',
              ]"
              @click="handleCountClick({ advId: item.id })">
              <encrypted-image-rc4
                :encrypted-image-url="getImgURL(item?.picUrl)"
                :alt="item.name"
                :is-placeholder="true"
                :key="item.id"
                :class-name="{
                  'w-[100px] md:w-28 aspect-square object-cover border-t border-b border-solid border-white rounded-xl [box-shadow:_0px_0px_10px_0px_rgba(0,_0,_0,_0.60)]': true,
                }" />
              <div class="flex flex-col gap-1 text-sm text-white">
                <p>{{ item.name }}</p>
                <div class="flex items-center gap-1">
                  <span
                    v-for="i in 5"
                    :key="i"
                    :class="[
                      'icon-star [&:before]:text-gray-900',
                      { '[&:before]:!text-[#82FFF7]': (item.rating || 0) >= i },
                    ]" />
                  <p class="ml-2">下载量：</p>
                  <div>
                    <span class="text-[#82FFF7]">{{ item?.downloadCount || 0 }}</span>
                    <span>万</span>
                  </div>
                </div>
                <p class="text-[#82FFF7]">{{ item?.description }}</p>
              </div>
            </a>
          </div>
        </div>

        <div class="flex flex-col gap-4">
          <div class="flex items-center gap-1 md:gap-2">
            <img src="@/assets/images/svg/icon.svg" alt="tag-icon" class="h-5 md:h-6" />
            <h6 class="text-[#FFCE4F] text-xl font-medium">联系我们</h6>
          </div>
          <div class="fade-none grid grid-cols-2 md:grid-cols-4 gap-y-4 xl:gap-y-6 gap-x-4 xl:gap-x-8">
            <a
              v-for="({ id, name, link, picUrl }, idx) in contacts"
              :key="idx"
              :href="link"
              target="_blank"
              :class="[
                'flex flex-col items-center gap-4 py-5 px-3 rounded-xl border-t border-[rgba(186,255,247,0.40)] cursor-pointer transition hover:-translate-y-1 hover:shadow-lg',
                'bg-gradient-to-b from-[rgba(196,168,255,0)] vai-[rgba(155,153,255,0.30)] to-[rgba(155,153,255,0.30)] shadow-[0px_2px_4px_0px_rgba(0,0,0,0.25)]',
              ]">
              <encrypted-image-rc4
                :encrypted-image-url="getImgURL(picUrl)"
                :alt="name"
                :is-placeholder="true"
                :key="id"
                :class-name="{ 'w-20 aspect-square object-contain': true }" />
              <p
                class="px-2 py-1 text-xs bg-gradient-to-b from-[#A0E8FF] to-transparent rounded-full border-b border-white text-white truncate">
                {{ name }}
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>

    <Modal :class="[{ '!hidden': isModalVisible }]" v-model="isModalShow">
      <template slot="content">
        <a
          v-for="({ id, name, link, picUrl }, idx) in announcements?.advList || []"
          :key="id"
          :href="link"
          target="_blank"
          rel="noopener noreferrer"
          class="animate-none w-full hidden absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 first:block"
          @click="handleCountClick({ advId: id })">
          <encrypted-image-rc4
            :encrypted-image-url="getImgURL(picUrl)"
            :alt="name"
            :key="id"
            :onImageLoaded="handleImageLoaded"
            :class-name="{ 'w-full': true }" />
          <span
            :class="[
              'w-6 h-6 md:w-10 md:h-10 flex justify-center items-center text-lg cursor-pointer rounded',
              'absolute top-0 right-0  bg-gray-900/50',
              'transition hover:bg-neutral-900/75',
            ]"
            @click.prevent="handleCloseModalAd($event, idx)">
            <span class="icon-close pointer-events-none"></span>
          </span>
        </a>
      </template>
    </Modal>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import 'swiper/css/swiper.css';

  import Modal from '@/components/Modal.vue';
  import EncryptedImage from '@/components/EncryptedImage.vue';
  import EncryptedImageRc4 from '@/components/EncryptedImageRc4.vue';

  export default {
    name: 'HomeView',
    components: {
      Swiper,
      SwiperSlide,
      Modal,
      EncryptedImage,
      EncryptedImageRc4,
    },
    data() {
      return {
        topBanner: {},
        centerBanner: {},
        selectedCategory: {},
        selectedDataList: [],
        isModalShow: true,
        isModalVisible: true,
        swiperOption: {
          autoplay: {
            delay: 2500,
            disableOnInteraction: false,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
        },
      };
    },
    computed: {
      ...mapGetters({
        categories: 'HomeModule/getCategories',
        playings: 'HomeModule/getPlayings',
        contacts: 'HomeModule/getContacts',
        advertisements: 'HomeModule/getAdvertisements',
        topBanners: 'HomeModule/getTopBanners',
        centerBanners: 'HomeModule/getCenterBanners',
        slideBanners: 'HomeModule/getSlideBanners',
        dataList: 'HomeModule/getDataList',
        announcements: 'HomeModule/getAnnouncements',
        notices: 'HomeModule/getNotices',
      }),
    },
    watch: {
      topBanners: function (val) {
        if (val && val?.advList && Array.isArray(val.advList)) {
          const index = Math.floor(Math.random() * val.advList.length);
          this.topBanner = val.advList[index];
        } else {
          this.topBanner = {};
        }
      },
      centerBanners: function (val) {
        if (val && val?.advList && Array.isArray(val.advList)) {
          const index = Math.floor(Math.random() * val.advList.length);
          this.centerBanner = val.advList[index];
        } else {
          this.centerBanner = {};
        }
      },
      categories: function (val) {
        if (val && Array.isArray(val) && val.length) {
          if (!this.selectedCategory?.id) {
            this.selectedCategory = val[0];
            this.setSelectedDataList(this.selectedCategory, this.dataList);
          }
        } else {
          this.selectedCategory = {};
        }
      },
      dataList: function (val) {
        if (this.selectedCategory?.id) {
          this.setSelectedDataList(this.selectedCategory, val);
        } else {
          this.selectedDataList = [];
        }
      },
    },
    created() {
      // binding scroll event
      window.addEventListener('scroll', this.handleScroll);
      // get home data
      this.fetchHome();
    },
    methods: {
      ...mapActions('HomeModule', ['fetchHome', 'handleCountClick']),
      handleScroll(e) {
        // root element
        const r = document.querySelector(':root');
        // get the styles (properties and values) for the root
        const rs = getComputedStyle(r);
        // reserve top value
        const reserveTopValue = 50;
        // add floating top property name
        const adFloatingTopProperty = '--ad-floating-top';
        // add floating bottom property name
        const adFloatingBottomProperty = '--ad-floating-bottom';
        // top position
        let topPosition = parseInt(rs.getPropertyValue('--ad-floating-top-default').slice(0, -3));
        // bottom position
        let bottomPosition = parseInt(rs.getPropertyValue('--ad-floating-bottom-default').slice(0, -3));

        if (window.pageYOffset && window.pageYOffset > reserveTopValue) {
          topPosition += this.pixelToRem(window.pageYOffset) - this.pixelToRem(reserveTopValue);
          bottomPosition = '-' + this.pixelToRem(window.pageYOffset);
        }

        r.style.setProperty(adFloatingTopProperty, topPosition + 'rem');
        r.style.setProperty(adFloatingBottomProperty, bottomPosition + 'rem');
      },
      remToPixel(rem) {
        return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
      },
      pixelToRem(pixel) {
        return pixel / parseFloat(getComputedStyle(document.documentElement).fontSize);
      },
      getImgURL(path) {
        if (!path) {
          return null;
        }

        if (/(http(s?)):\/\//i.test(path)) {
          return path;
        }

        return process.env.VUE_APP_IMG_BASE + '/assets' + path;
      },
      handleMenuClick(id) {
        const index = this.categories.findIndex(obj => obj.id === id);
        this.selectedCategory = this.categories[index];
        this.setSelectedDataList(this.selectedCategory, this.dataList);
      },
      setSelectedDataList(category, dataList) {
        if (dataList && dataList.advList && Array.isArray(dataList.advList)) {
          this.selectedDataList = dataList.advList.filter(obj => obj.category === category.name);
        } else {
          this.selectedDataList = [];
        }
      },
      toggleWindowAdModalStatus(bool) {
        if (bool !== null) {
          this.isModalShow = bool;
        } else {
          this.isModalShow = !this.isModalShow;
        }
      },
      handleImageLoaded(result) {
        if (result.success) {
          this.isModalVisible = false;
        }
      },
      handleCloseModalAd(ev, idx) {
        if (ev.target.parentElement) {
          ev.target.parentElement.remove();
          if (idx + 1 >= this.announcements.advList.length) {
            this.isModalShow = false;
          }
        }
      },
    },
  };
</script>
